import './Register.css';

export default function SignUp() {

    return (
        <>
            <div className="successfulText">
                Je account is succesvol aangemaakt!
            </div>
        </>
    )
}